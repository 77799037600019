import React, { useEffect } from 'react'

const BookAtDoncaster = () => {
    useEffect(() => {

        const scr = document.createElement("script");
        scr.src = 'https://mimik-sushi-richmond.resos.com/embed/booking/widget.js?ts=' + new Date().getTime(); document.getElementById("booking-script-2").appendChild(scr);
        scr.type = "text/javascript";
        scr.async = true;

        // return () => {
        //     // Clean up the script then component is unmounted
        //     document.getElementById("booking-script-2").removeChild(scr);
        // }
    }, [])

    return (
        <>
            <div className="text-center">
                <a id="booking-widget-2" href="https://mimik-sushi-doncaster.resos.com/booking" data-lang="en" data-restaurant-id="sy75jM2MRm46ELXxf" data-domain="mimik-sushi-doncaster.resos.com">Book at Richmond</a>
            </div>
            <div id="booking-script-2" className="text-center">
                <p>Powered by <a target="_blank" rel='noreferrer' href="https://resos.com">resOS</a></p>
            </div>
        </>
    )
}

export default BookAtDoncaster