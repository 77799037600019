//imports
import { NavLink } from "react-router-dom"
import SEO from "../Components/SEO"
import { Element } from "react-scroll"

function Locations() {
  return (
    <>
      <SEO
        title="Mimik Sushi - Locations"
        description="We are located in Acton and Richmond"
        name="Mimik Sushi - Locations"
        type="website"
        url="https://www.mimiksushi.com/locations"
      />
      <Element name="locations-section">
        <section id="locations" className="locations">
          <div className="container" data-aos="fade-up">
            <div className="section-header">
              <p><span>OUR LOCATIONS</span></p>
            </div>
            <div className="row gy-4 justify-content-center">
              <div className="col-sm-6 mb-3 mb-sm-0">
                <div className="card h-100 rounded-0">
                  <div className="card-body text-center">
                    <h5 className="card-title">Mimik Acton</h5>
                    <p className="card-text address">269 High St, London  <br /> W3 9BT</p>
                    <NavLink className="btn-locations" to="/booking" >BOOK NOW</NavLink>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 mb-3 mb-sm-0">
                <div className="card h-100 rounded-0">
                  <div className="card-body text-center">
                    <h5 className="card-title">Mimik Doncaster</h5>
                    <p className="card-text address">3 Wood Street, Doncaster <br /> DN1 3LH</p>
                    <NavLink className="btn-locations" to="/booking" >BOOK NOW</NavLink>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>
      </Element>
    </>
  )
}

export default Locations