import { useState } from "react";
import SEO from "../Components/SEO";
import MenuContent from "../Components/MenuContent";
import { Element } from "react-scroll";

// import data
import { actonBranchMenu, richmondBranchMenu, doncasterBranchMenu } from "../data/menuData";

function Menu() {
  const [currentMenu, setCurrentMenu] = useState('acton');
  const setCurrentMenuLocation = (location) => {
    setCurrentMenu(location)
  }

  return (
    <>
      <SEO
        title="Mimik Sushi — Menu"
        description="We are a concept sushi restaurant with a personal touch. We believe that good food is for everyone and everyone should try it. Our menu caters for everyone who likes good food and it`s open for an experience once you come to our location. Sushi is our passion.
                     Check our Mimik Menu here."
        name="Mimik Sushi - Menu"
        type="website"
        url="https://www.mimiksushi.com/menu"
      />
      <Element name="menu-section">
        <section id="menu" className="menu">

          <div className="container" data-aos="fade-up">
            <div className="section-header">
              <p><span>OUR MENU</span></p>
            </div>
            <div className="tab-content" data-aos="fade-up" data-aos-delay="300">
              <div className="justify-content-center d-flex mx-auto my-5 row">
                <button onClick={() => { setCurrentMenuLocation('acton') }} className={`btn-menu mx-5 my-2 ${currentMenu === 'acton' ? 'location-menu' : ''}`}>Acton Menu</button>
                <button onClick={() => { setCurrentMenuLocation('doncaster') }} className={`btn-menu mx-5 my-2 ${currentMenu === 'doncaster' ? 'location-menu' : ''}`}>Doncaster Menu</button>
              </div>

              {
                currentMenu === 'acton' ? <MenuContent value={actonBranchMenu} /> : ''
              }

              {
                currentMenu === 'doncaster' ? <MenuContent value={doncasterBranchMenu} /> : ''
              }

              <div className="text-center pt-5">
                <a href='assets/files/AllYouCanEat!.pdf' rel="noopener noreferrer" target="_blank"><button className='btn-menu me-3 mt-3'>Download All You Can Eat Menu</button></a>
              </div>
              <div className="text-center pt-3">
                <p className="fw-bold fs-6" >*All-You-Can-Eat promo is not available at Doncaster</p>
              </div>
            </div>
          </div>
        </section>
      </Element>
    </>
  )
}

export default Menu;